<template>
  <div class="popover-coupon">
    <img
      src="https://img.ltwebstatic.com/images3_ccc/2025/01/06/9e/17361481914ae8cf80a82f29d77378f00ee5764222.png"
      class="popover-coupon__bg"
    />

    <div
      v-if="iconText"
      class="top"
    >
      <ResizeFont ref="refResizeFont">
        <ResizeFontText
          :init-font-size="18"
          :resize-font-min="12"
          :resize-font-step="1"
        >
          {{ iconText }}
        </ResizeFontText>
      </ResizeFont>
    </div>
    <div
      v-if="iconTextOff"
      class="bottom"
    >
      {{ iconTextOff }}
    </div>

    <div
      v-if="couponLabel"
      class="popover-coupon__flag"
      :style="{
        color: couponLabel.textColor,
        backgroundColor: couponLabel.bgColor,
      }"
    >
      <div>{{ couponLabel.text }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import ResizeFont from '@/public/src/pages/cart_v2/components/common/ResizeFont.vue'
import ResizeFontText from '@/public/src/pages/cart_v2/components/common/ResizeFontText.vue'

export default defineComponent({
  name: 'FooterPopoverCoupon',
  components: {
    ResizeFont,
    ResizeFontText,
  },
  props: {
    iconText: {
      type: String,
      default: '',
    },
    iconTextOff: {
      type: String,
      default: '',
    },
    couponLabel: {
      type: Object,
      default: null,
    },
  },
  watch: {
    iconText() {
      this.$refs.refResizeFont.update()
    }
  }
})
</script>

<style lang="less" scoped>
.popover-coupon {
  position: relative;
  width: 64/37.5rem;
  height: 38/37.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__bg {
    position: absolute;
    left: 0;
    z-index: 0;
    height: 36/37.5rem;
    transform-origin: left;
  }
  &__flag {
    position: absolute;
    top: 1/37.5rem;
    right: -6/37.5rem;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    height: 18px;
    font-size: 12px;
    font-weight: 700;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    white-space: nowrap;
    transform: scale(0.5);
    transform-origin: top right;
  }
  .top {
    z-index: 1;
    color: #FA6338;
    width: 88%;
    line-height: 1;
    font-size: 12px;
    font-weight: 1000;
    overflow: hidden;
    text-align: center;
  }
  .bottom {
    z-index: 1;
    margin: 0 auto;
    margin-top: 2/37.5rem;
    max-width: 56/37.5rem;
    width: 88%;
    line-height: 8px;
    font-size: 8px;
    font-weight: 600;
    color: #FA6338;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
