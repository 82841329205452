<template>
  <transition :name="transitionName">
    <div
      v-if="show"
      :key="bubble.type"
      :class="rootCls"
      :style="style"
      @click.stop
      @pointerdown.stop
    >
      <Icon
        class="close-ico"
        aria-hidden="true"
        size="12"
        name="sui_icon_close_18px"
        color="#C1C1C1"
        @click="onClose"
      />
      <div
        class="tips"
        v-html="clickText"
      >
      </div>
      <div
        class="content"
        @click="onClick"
      >
        <div class="left">
          <PopoverCoupon
            v-if="bubbleType === 'coupon'"
            :icon-text="iconText"
            :icon-text-off="iconTextOff"
          />
          <PopoverReturncouponNew
            v-else-if="bubbleType === 'order_return_coupon' && prefixIcon"
            :coupon-type="couponType"
            :prefix-icon="prefixIcon"
            :icon-text="iconText"
            :icon-text-off="iconTextOff"
            :coupon-label="couponLabel"
          />
          <PopoverReturncoupon
            v-else-if="bubbleType === 'order_return_coupon'"
            :lure-point-item-type="lurePointItemType"
            :icon-text="iconText"
            :icon-text-off="iconTextOff"
            :coupon-num="couponNum"
          />
          <PopoverImg
            v-else-if="showPopoverImg"
            :url="prefixIcon"
          />
          <PopoverGoods
            v-else
            :type="bubbleType"
            :goods="goods"
            :goods-num="goodsNum"
          />
        </div>
        <div class="right">
          <s-button
            :type="['H56PX', 'primary']"
            class="go-cart"
          >
            {{ clickBtnText }}
          </s-button>
          <span
            v-if="clickBtnLabel"
            class="go-cart-flag"
            :style="{
              '--bg-color': clickBtnLabel.bgColor,
              '--text-color': clickBtnLabel.textColor,
            }"
          >
            <img
              v-if="clickBtnLabel.icon"
              :src="clickBtnLabel.icon"
              style="width: 12px;height: 12px;"
            />
            <span>{{ clickBtnLabel.text }}</span>
          </span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import PopoverImg from './img'
import PopoverGoods from './goods.vue'
import PopoverCoupon from './coupon.vue'
import PopoverReturncouponNew from './returncouponNew.vue'
import PopoverReturncoupon from './returncoupon.vue'
import { daEventCenter } from '@/public/src/services/eventCenter'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { jumpCart } from '../utils'
import { useLastLureBubble } from '../hooks/useLastLureBubble'

daEventCenter.addSubscriber({ modulecode: '2-15-1' })

export default defineComponent({
  components: {
    Icon,
    PopoverImg,
    PopoverGoods,
    PopoverCoupon,
    PopoverReturncouponNew,
    PopoverReturncoupon,
    SButton,
  },
  setup() {
    const { setLastLureBubble } = useLastLureBubble()
    return {
      setLastLureBubble,
    }
  },
  props: {
    styleConfig: {
      type: Object,
      default: () => ({})
    },
    appStyleControlStyle: {
      type: String,
      default: null,
    },
    scene: {
      type: String,
      default: '', // QuickCart
    },
  },
  data() {
    return {
      bubble: {},
      show: false,
      callback: null,

      timer: null,
    }
  },
  computed: {
    rootCls() {
      const res = ['footer-cart-popover']
      if (this.scene) {
        res.push('footer-cart-popover_quick-cart')
      }
      return res
    },
    transitionName() {
      if (this.scene === 'QuickCart') return 'quick-cart-popover'
      return 'footer-cart-popover'
    },
    style() {
      if (this.scene === 'QuickCart') {
        return {
          '--triangle-left': '88%',
        }
      }
      return {
        '--triangle-left': '50%',
      }
    },
    prefixIcon() {
      return this.bubble.lurePointItem?.bubble.prefixIcon
    },
    clickText() {
      return this.bubble.lurePointItem?.bubble.clickText
    },
    clickBtnText() {
      return this.bubble.lurePointItem?.bubble.clickBtnText
    },
    clickBtnLabel() {
      return this.bubble.lurePointItem?.bubble.clickBtnLabels?.[0]
    },
    iconText() {
      return this.bubble.lurePointItem?.bubble.iconText
    },
    iconTextOff() {
      return this.bubble.lurePointItem?.bubble.iconTextOff
    },
    bubbleType() {
      return this.bubble.type
    },
    lurePointItemType() {
      return this.bubble.lurePointItem?.type
    },
    goods() {
      return this.bubble.lurePointItem?.bubble.goods || []
    },
    goodsNum() {
      return this.bubble.lurePointItem?.bubble.goodsNum ?? 0
    },
    couponType() {
      return this.bubble.lurePointItem?.bubble.couponType ?? '0'
    },
    couponNum() {
      return this.bubble.lurePointItem?.bubble.couponNum ?? 0
    },
    couponLabel() {
      return this.bubble.lurePointItem?.bubble.couponLabel
    },
    showPopoverImg() {
      if (this.bubbleType === 'gift') {
        if (this.appStyleControlStyle === '1') {
          return this.bubble.lurePointItem?.hasAllChecked !== '1'
        } else {
          return this.bubble.lurePointItem?.hasChecked !== '1'
        }
      }
      if (this.bubbleType === 'freeshipping') {
        return !!this.prefixIcon
      }
      return false
    },
  },
  methods: {
    /**
     * @param bubble
     * @param bubble.type
     * @param bubble.uniqueType
     * @param bubble.freeShippingType
     * @param bubble.text
     * @param bubble.htmlText
     * @param bubble.desc
     * @param bubble.lurePointItem
     * @param bubble.lurePointItem.type
     * @param bubble.lurePointItem.uniqueType
     * @param bubble.lurePointItem.cacheKey
     * @param bubble.lurePointItem.identify
     * @param bubble.lurePointItem.hasChecked
     * @param bubble.lurePointItem.hasAllChecked
     * @param bubble.lurePointItem.cartIds
     * @param bubble.lurePointItem.bubble
     * @param bubble.lurePointItem.bubble.prefixIcon
     * @param bubble.lurePointItem.bubble.iconText
     * @param bubble.lurePointItem.bubble.iconTextOff
     * @param bubble.lurePointItem.bubble.clickText
     * @param bubble.lurePointItem.bubble.clickBtnText
     * @param bubble.lurePointItem.bubble.goods
     * @param bubble.lurePointItem.bubble.goodsNum
     * @param bubble.lurePointItem.bubble.couponType
     * @param bubble.lurePointItem.bubble.couponNum
     * @param bubble.lurePointItem.bubble.couponLabel
     */
    open(bubble, config = {}) {
      this.callback = config.callback
      this.bubble = bubble
      this.show = true
      window?._gb_cart_tag_tips_?.getExposeData().then(extraData => {
        daEventCenter.triggerNotice({
          daId: '2-15-2',
          extraData,
        })
      })

      if (!this.timer) {
        this.timer = setTimeout(() => {
          this.afterEnter()
        }, 5000)
      }
    },
    clearTimer() {
      if (!this.timer) return
      clearTimeout(this.timer)
      this.timer = null
    },
    afterEnter(jump) {
      this.clearTimer()
      this.show = false
      if (typeof this.callback === 'function') {
        if (!jump) this.callback()
        this.callback = null
      }
    },
    async onClick() {
      this.setLastLureBubble(this.bubble?.lurePointItem?.uniqueType)
      jumpCart(this.bubble, this.appStyleControlStyle)
    },
    onClose() {
      this.$emit('close')
      this.afterEnter(true)
    },
    // 打断动画
    clear() {
      this.afterEnter(true)
    },
  },
})
</script>

<style lang="less" scoped>
.footer-cart-popover {
  position: absolute;
  left: -203/75rem;
  bottom: calc(100% + 10px);
  margin-left: 50%;
  padding: 16/75rem;
  width: 406/75rem;
  color: #fff;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  &_quick-cart {
    left: -406/75 + 0.96 + 12/75rem;
    margin-left: 0;
  }
  &::before {
    content: '';
    position: absolute;
    left: var(--triangle-left);
    margin-left: -2/37.5rem;
    border-top: 4/37.5rem solid transparent;
    border-bottom: 4/37.5rem solid rgba(0, 0, 0, 0.8);
    border-right: 4/37.5rem solid rgba(0, 0, 0, 0.8);
    border-left: 4/37.5rem solid transparent;
    transform: rotate(45deg);
    bottom: -4/37.5rem;
  }

  .tips {
    padding-right: 8/37.5rem;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .content {
    margin-top: 8/37.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      flex: 1;
      margin-right: 16/37.5rem;
    }
    .right {
      position: relative;
    }
    .go-cart {
      background: #FA6338;
      font-size: 12px;
      border-radius: 24px;
      width: 84/37.5rem;
      padding: 0 4/37.5rem;
      font-weight: normal;
    }
    .go-cart-flag {
      position: absolute;
      top: -6px;
      right: 0;
      display: flex;
      align-items: center;
      gap: 2px;
      padding: 0 4px;
      height: 14px;
      font-size: 8px;
      color: var(--text-color, #fff);;
      max-width: 100%;
      background-color: var(--bg-color, #198055);
      border: 1px solid #fff;
      border-radius: 12px;

      > svg {
        flex-shrink: 0;
      }

      > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .close-ico {
    position: absolute;
    right: 3px;
    top: 3px;
  }
}

.footer-cart-popover-enter-active,
.footer-cart-popover-leave-active {
  transition: transform 0.25s;
  transform-origin: bottom;
}

.footer-cart-popover-enter-from,
.footer-cart-popover-leave-to {
  transform: scale(0);
}

.quick-cart-popover-enter-active,
.quick-cart-popover-leave-active {
  transition: transform 0.25s;
  transform-origin: 88% 110%;
}

.quick-cart-popover-enter-from,
.quick-cart-popover-leave-to {
  transform: scale(0);
}
</style>
