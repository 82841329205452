<template>
  <div class="popover-img">
    <img :src="src" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FooterPopoverImg',
  props: {
    url: {
      type: String,
      default: '',
    },
  },
  computed: {
    src() {
      // 默认为赠品图片
      return this.url || 'https://img.ltwebstatic.com/images3_ccc/2024/08/15/44/1723693283ff5b69a86e97bca2e06d713790c69099.png'
    },
  },
})
</script>

<style lang="less" scoped>
.popover-img {
  img {
    height: 38px;
  }
}
</style>
