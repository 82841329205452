<template>
  <div
    class="popover-returncoupon"
    :class="{
      'popover-returncoupon_multitude': isMultitude,
      'popover-returncoupon_free': isFreeshipping,
      'popover-returncoupon_withfree': hasWithFreeshipping
    }"
  >
    <img
      :src="prefixIcon"
      class="popover-returncoupon__bg"
    />

    <div
      v-if="iconText"
      class="top"
    >
      <ResizeFont
        ref="refResizeFont"
        :is-multiline="isMultiline"
        word-break="break-word"
        :multiline-num="2"
      >
        <ResizeFontText
          :init-font-size="18"
          :resize-font-min="12"
          :resize-font-step="1"
        >
          {{ iconText }}
        </ResizeFontText>
      </ResizeFont>
    </div>
    <div
      v-if="iconTextOff"
      class="bottom"
    >
      {{ iconTextOff }}
    </div>

    <div
      v-if="couponLabel"
      class="popover-returncoupon__flag"
      :style="{
        color: couponLabel.textColor,
        backgroundColor: couponLabel.bgColor,
      }"
    >
      <div>{{ couponLabel.text }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import ResizeFont from '@/public/src/pages/cart_v2/components/common/ResizeFont.vue'
import ResizeFontText from '@/public/src/pages/cart_v2/components/common/ResizeFontText.vue'

export default defineComponent({
  name: 'FooterPopoverCoupon',
  components: {
    ResizeFont,
    ResizeFontText,
  },
  props: {
    couponType: {
      type: String,
      default: '',
    },
    prefixIcon: {
      type: String,
      default: '',
    },
    iconText: {
      type: String,
      default: '',
    },
    iconTextOff: {
      type: String,
      default: '',
    },
    couponLabel: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isMultitude() {
      return ['4', '5', '6', '8', '9', '10'].includes(this.couponType)
    },
    isFreeshipping() {
      return ['3', '10'].includes(this.couponType)
    },
    hasWithFreeshipping() {
      return ['5', '6', '8'].includes(this.couponType)
    },
    isMultiline() {
      return this.isFreeshipping
    },
  },
  watch: {
    iconText() {
      this.$refs.refResizeFont.update()
    }
  },
})
</script>

<style lang="less" scoped>
.popover-returncoupon {
  position: relative;
  width: 64/37.5rem;
  height: 38/37.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__bg {
    position: absolute;
    left: 0;
    z-index: 0;
    height: 36/37.5rem;
    transform-origin: left;
  }
  &_multitude {
    .popover-returncoupon__bg {
      height: 38/37.5rem;
      transform: translateX(-1px);
    }
  }
  &_free.popover-returncoupon {
    .top, .bottom {
      color: #169E00;
    }
  }
  &_withfree {
    .popover-returncoupon__flag {
      top: -1/37.5rem;
      border-top-left-radius: 8px;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 0;
      border: 1px solid #fff;
    }
  }
  &__flag {
    position: absolute;
    top: 1/37.5rem;
    right: -6/37.5rem;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    height: 18px;
    font-size: 12px;
    font-weight: 700;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    white-space: nowrap;
    transform: scale(0.5);
    transform-origin: top right;
  }
  .top {
    z-index: 1;
    color: #3E2D1D;
    width: 88%;
    line-height: 1;
    font-size: 12px;
    font-weight: 1000;
    overflow: hidden;
    text-align: center;
  }
  .bottom {
    z-index: 1;
    margin: 0 auto;
    max-width: 56/37.5rem;
    width: 88%;
    line-height: 8px;
    font-size: 8px;
    font-weight: 600;
    color: #3E2D1D;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
