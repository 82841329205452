<template>
  <div class="popover-goods">
    <template v-if="goods.length">
      <div
        v-for="(item, i) in goods"
        :key="i"
        class="goods"
      >
        <img
          :src="item.goods_img || item.goodsImg"
          width="100%"
          height="100%"
        />

        <div
          v-show="getShowDiscount(item)"
          class="unit-discount"
        >
          <span>{{
            (lang != 'il' ? '-' : '') + item.unit_discount + '%'
          }}</span>
        </div>

        <div
          v-if="item.belt"
          class="belt"
          :style="getBeltStyle(item.belt)"
        >
          {{ item.belt.text }}
        </div>

        <div
          v-if="i == 1 && modalText"
          class="modal"
        >
          <span>{{ modalText }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { hexToRgb } from '@shein/common-function'

const { lang, IS_SUGGESTED } = gbCommonInfo

export default defineComponent({
  name: 'FooterPopoverGoods',
  props: {
    type: {
      type: String,
      default: ''
    },
    goods: {
      type: Array,
      default: () => []
    },
    goodsNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      lang
    }
  },
  computed: {
    modalText() {
      let length = this.goodsNum
      if (length > 2 && length <= 100) return `+${length - 1}`
      if (length > 100) return '99+'
      return ''
    }
  },
  methods: {
    getShowDiscount(item) {
      return !IS_SUGGESTED && this.type !== 'gift' && item.unit_discount != 0
    },
    getBeltStyle(belt) {
      return {
        color: belt.textColor,
        background: hexToRgb({ hex: belt.bgColor, opa: belt.bgColorAlpha }),
      }
    },
  },
})
</script>

<style lang="less" scoped>
.popover-goods {
  display: flex;
  .goods {
    position: relative;
    width: 38/37.5rem;
    height: 38/37.5rem;
    margin-right: 4/37.5rem;
    .unit-discount {
      position: absolute;
      right: auto;
      text-align: center;
      font-size: 10px;
      color: #fff;
      direction: ltr /* rtl:ignore */;
      /* sh:begin */
      min-width: 2.75em;
      line-height: 1.35;
      top: 0;
      left: 0 /* rtl:ignore */;
      right: unset /* rtl:ignore */;
      background: @sui_color_discount;
    }
    .belt {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 12px;
      line-height: 12px;
      font-size: 10px;
      text-align: center;
    }
    .modal {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.7);
      font-size: 12px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
